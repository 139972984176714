import { sharedStorage } from '../storage'

/**
 * Generate a random string of the given length.
 *
 * The implementation enforces the randomness by using the {@link Crypto} API.
 */
function getRandomString(length = 9) {
  const charset =
    '0123456789ABCDEFGHIJKLMNOPQRSTUVXYZabcdefghijklmnopqrstuvwxyz-._~'

  return Array.from(crypto.getRandomValues(new Uint8Array(length)))
    .map((x) => charset[x % charset.length])
    .join('')
}

export const CACHE_KEY = 'state'

/**
 * This custom error represents the case where we try to retrieve the auth
 * state from the shared storage, but none if found.
 *
 * Make sure to execute {@link useAuthState} before {@link useStoredAuthState}.
 */
export class MissingStoredAuthStateError extends Error {}

/**
 * Generate a new random auth state.
 *
 * Note that the state is stored in our shared storage for later use. It is
 * expected to retrieve it using the {@link useStoredAuthState} composable.
 */
export function useAuthState() {
  const state = getRandomString()

  sharedStorage.setItem(CACHE_KEY, state)

  return state
}

/**
 * Retrieve the auth state previously stored in the shared storage.
 *
 * @throws {MissingStoredAuthStateError} when no state is found.
 */
export function useStoredAuthState() {
  const state = sharedStorage.getItem(CACHE_KEY)

  if (state === null) {
    throw new MissingStoredAuthStateError('No auth state found in store')
  }

  return state
}
