import { deleteBrowserCookie } from '@backmarket/utils/cookies/deleteBrowserCookie'
import { getBrowserCookie } from '@backmarket/utils/cookies/getBrowserCookie'
import { setBrowserCookie } from '@backmarket/utils/cookies/setBrowserCookie'

/**
 * For our own use case, we are not interested with all properties and methods
 * from the native Storage class. We only define the ones we're using.
 */
type CustomStorage = Pick<Storage, 'getItem' | 'setItem' | 'removeItem'>

/**
 * Add a simple prefix used when storing values in storage. Since key names are
 * quite generic, this is to avoid any conflicts with other stored values.
 */
const toInternalKey = (key: string) => `oauth-storage-${key}`

/**
 * Storage scoped to one tab.
 *
 * - Each tab has its own dedicated storage.
 * - It's not possible to read from one storage to another.
 * - It's automatically cleared when the associated tab is closed.
 */
export const scopedStorage: CustomStorage = {
  getItem(key) {
    try {
      return window.sessionStorage.getItem(toInternalKey(key))
    } catch {
      return null
    }
  },
  setItem(key, value) {
    try {
      return window.sessionStorage.setItem(toInternalKey(key), value)
    } catch {
      return null
    }
  },
  removeItem(key) {
    try {
      return window.sessionStorage.removeItem(toInternalKey(key))
    } catch {
      return null
    }
  },
}

/**
 * Storage shared among all tabs.
 *
 * - All tabs store to and read from the same place.
 * - Content is persisted across tabs and browsers restarts.
 *
 * Note that it falls back on a cookie-based storage if anything fails.
 */
export const sharedStorage: CustomStorage = {
  getItem(key) {
    try {
      return window.localStorage.getItem(toInternalKey(key))
    } catch {
      return getBrowserCookie(toInternalKey(key)) ?? null
    }
  },
  setItem(key, value) {
    try {
      return window.localStorage.setItem(toInternalKey(key), value)
    } catch {
      return setBrowserCookie(toInternalKey(key), value)
    }
  },
  removeItem(key) {
    try {
      return window.localStorage.removeItem(toInternalKey(key))
    } catch {
      return deleteBrowserCookie(toInternalKey(key))
    }
  },
}
