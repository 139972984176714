import { oauthAPI } from '@backmarket/http-api'
import { $httpFetch } from '@backmarket/nuxt-module-http/$httpFetch'

import { scopedStorage } from '../storage'

export type Endpoints = {
  revocationEndpoint: string
  tokenEndpoint: string
}

export const CACHE_KEY = 'endpoints'

/**
 * Get OAuth-related endpoints.
 *
 * If the endpoints have already been fetched, then read them from the local
 * cache. Otherwise, retrieve them from the OpenID configuration file.
 *
 * If anything fails, throws an error..
 *
 * @example
 * const domain = 'https://accounts.backmarket.com'
 * const endpoints = await useEndpoints(domain)
 *
 * @param domain The domain to fetch the OpenID configuration from.
 * @return A dictionary of OAuth-related endpoints.
 */
export async function useEndpoints(domain: string): Promise<Endpoints> {
  const cachedEndpoints = scopedStorage.getItem(CACHE_KEY)

  if (cachedEndpoints) {
    return JSON.parse(cachedEndpoints)
  }

  const endpoints = await $httpFetch(oauthAPI.getEndpoints, {
    baseURL: domain,
  })

  scopedStorage.setItem(CACHE_KEY, JSON.stringify(endpoints))

  return endpoints
}
