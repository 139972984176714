import { createHttpEndpoint } from '../../utils'

type GetEndpointsResponseBody = {
  revocationEndpoint: string
  tokenEndpoint: string
}

/**
 * Get OAuth-related endpoints from the OpenID configuration file.
 */
export const getEndpoints = createHttpEndpoint<GetEndpointsResponseBody>({
  method: 'GET',
  path: '/.well-known/openid-configuration',
  operationId: 'getOauthEndpoints',
  transformResponseToCamelCase: true,
})
