import generatePkce from 'pkce-challenge'

import { sharedStorage } from '../storage'

export const CACHE_KEY = 'pkce-verifier'

/**
 * This custom error represents the case where we try to retrieve the PKCE
 * verifier from the shared storage, but none if found.
 *
 * Make sure to execute `usePkce` before calling `usePkceVerifier`.
 */
export class MissingStoredPkceVerifierError extends Error {}

/**
 * Generate a random couple of PKCE challenge and verifier.
 *
 * Note that the PKCE verifier is cached in shared storage for later use. It
 * is expected to retrieve it using the `usePkceVerifier` composable.
 */
export function usePkce() {
  const pkce = generatePkce()

  sharedStorage.setItem(CACHE_KEY, pkce.code_verifier)

  return {
    challenge: pkce.code_challenge,
    verifier: pkce.code_verifier,
  }
}

/**
 * Retrieve the PKCE verifier previously stored in the shared storage.
 *
 * @throws {MissingStoredPkceVerifierError} when no verifier is found.
 */
export function useStoredPkceVerifier() {
  const verifier = sharedStorage.getItem(CACHE_KEY)

  if (verifier === null) {
    throw new MissingStoredPkceVerifierError('No PKCE verifier found in cache')
  }

  return verifier
}
